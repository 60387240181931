import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { datastore } from 'app/config';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private _firestore: AngularFirestore,
  ) { }

  loadTests(courseId, moduleId) {
    return this._firestore.collection(`${datastore}/courses/${courseId}/modules/${moduleId}/testes`).get().toPromise();
  }

  loadActivities(courseId, moduleId) {
    return this._firestore.collection(`${datastore}/courses/${courseId}/modules/${moduleId}/cadastroAtividade`).get().toPromise();
  }

  createEvent(courseId, moduleId, data) {
    return this._firestore.collection(`${datastore}/courses/${courseId}/modules/${moduleId}/calendar`)
      .add(data)
  }

  loadCalendarEvents(courseId, moduleId) {
    return this._firestore.collection(`${datastore}/courses/${courseId}/modules/${moduleId}/calendar`)
  }
}
