import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Injectable } from '@angular/core';
import { datastore } from 'app/config';
import * as firebase from 'firebase';


@Injectable({
	providedIn: 'root'
})
export class SecretaryService {

	constructor(
		private db: AngularFirestore,
		) { }


	newCall(idUser, dataForm) {
		
		return new Promise((resolve, reject) => {
			this.db.collection(`${datastore}/secretary/`)
				.add({ ...dataForm, timestamp: firebase.firestore.FieldValue.serverTimestamp() }).then(res => {
					console.log(res)
				})
		})
	}

	getCallByUser(userId) {
		let itens = [];
		this.db.firestore.collection(`${datastore}/secretary/`).where('idUser', '==',userId).get()
		.then((snapshot) => {
			snapshot.docs.forEach(doc =>{
				console.log(doc.data());
				itens.push(doc.data());
			});
		});
		
		return itens;
	}
	
	// teste(){
	// 	let itens = [];
	// 	this.db.firestore.collection(`${datastore}/secretary/`).where('status', '==', 'aberto').get()
	// 	.then((snapshot) => {
	// 		snapshot.docs.forEach(doc =>{
	// 			itens.push(doc.data());
	// 		});
	// 	});
		
	// 	return itens;
	// }

	getAllCall() {
		let itens = [];
		this.db.firestore.collection(`${datastore}/secretary/`).where('status', '==','aberto').get()
		.then((snapshot) => {
			snapshot.docs.forEach(doc =>{
				var chamados = doc.data();
				chamados.idDoc = doc.id;
				itens.push(chamados);
			});
		});

		return itens
		
		// return itens;
		// return this.db.firestore.collection(`${datastore}/secretary/`)
		// // .where('status', '==', 'aberto')
		// .orderBy('timestamp')
		//  .where('status', '==', 'respondido')
		// 	let itens = snapshot.docChanges();
		// 	itens.forEach(item =>{
		// 		let teste = item.doc.data();
		// 		console.log(teste);
		// 	})
		//  });
	}

	answerCall(idDoc, formData){
		this.db.collection(`${datastore}/secretary/`).doc(idDoc).update(formData)
	}
}
