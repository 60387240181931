import { Injectable } from '@angular/core';
import { datastore } from 'app/config';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LoginPageService {

  constructor(
    private _firestore: AngularFirestore
  ) {

  }

  saveLoginConfig(config) {
    return this._firestore.collection(
      `${datastore}/login_config`
    ).doc("config").set({...config})
  }

  getLoginConfig() {
    return this._firestore.collection(
      `${datastore}/login_config`
    ).doc('config').get().toPromise();
  }
}
